.footer {
  position: relative;
}

/* Social Media */
.social-media {
  padding: 60px 0 10px;
  height: auto;
}

.social-media ul {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.contactIcon {
  transition: 0.5s;
}

.contactIcon:hover {
  fill: #b1c3d7;
}

/* Created With */
.createdwith {
  text-align: center;
  padding: 0 0 40px;
}
