.hero {
  padding: 40px 70px 40px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.hero-img img {
  -webkit-filter: sepia(0%);
  filter: sepia(0%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hero-img:hover img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

@media (max-width: 768px) {
  .hero {
    padding: 40px 20px 40px;
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 45px;
  }

  .hero-heading {
    text-align: center;
  }
}
