@import url("Utilities.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;250;300;350;400;450;500;550;600;650;700&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

html {
  overscroll-behavior: none;
}

:root {
  --primary-blue: #dbecff;
  --primary-beige: #e2d9ca;
  --primary-green: #d1d9d1;
  --primary-dark-blue: #9dbfff;
  --primary-grey2: #f4f6fc;
}

br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 5px; /* change this to whatever height you want it */
}

body {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: #333;
  line-height: 1.5;
  background-color: #fff;
}

a {
  text-decoration: none;
  color: #333;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

h2 {
  padding-bottom: 12px;
}

@media (max-width: 768px) {
  body {
    font-size: 15px;
    font-weight: 250;
  }
}
