/* Containers */
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-sm {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-xs {
  max-width: 600px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-md {
  max-width: 950px;
  margin: 0 auto;
  padding: 0 15px;
}

.container-lg {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Text Classes */
.text-xxxl {
  font-size: 6rem;
  line-height: 1.2;
  font-weight: 500;
  margin: 40px 0 30px;
}

.text-xxl {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 500;
  margin: 30px 0 20px;
}

.text-xl {
  font-size: 2.2rem;
  line-height: 1.4;
  font-weight: normal;
  margin: 40px 0 20px;
}

.text-lg {
  font-size: 1.8rem;
  line-height: 1.4;
  font-weight: normal;
  margin: 30px 0 20px;
}

.text-md {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: normal;
  margin: 20px 0 10px;
}

.text-sm {
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: normal;
  margin: 10px 0 5px;
}

.text-xs {
  font-size: 0.7rem;
  line-height: 1.4;
  font-weight: normal;
  margin: 10px 0 5px;
}

.text-center {
  text-align: center;
}

/* Backgrounds */
.bg-blue {
  background-color: var(--primary-blue);
}

.bg-beige {
  background-color: var(--primary-beige);
}

.bg-green {
  background-color: var(--primary-green);
}

.bg-dark-blue {
  background-color: var(--primary-dark-blue);
}

.bg-splashpage {
  background-color: #add2ff;
}

.bg-grey2 {
  background-color: var(--primary-grey2);
}

.bg-yellow {
  background-color: #f8f4eb;
}

@media (max-width: 768px) {
  .text-xxxl {
    font-size: 4rem;
    line-height: 1.2;
    font-weight: 500;
    margin: 50px 0 10px;
  }

  .text-xxl {
    font-size: 3rem;
    line-height: 1.2;
    font-weight: 500;
    margin: 30px 0 10px;
  }

  .text-xl {
    font-size: 2.2rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 40px 0 10px;
  }

  .text-lg {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 30px 0 8px;
  }

  .text-md {
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 20px 0 8px;
  }

  .text-sm {
    font-size: 0.9rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 10px 0 5px;
  }

  .text-xs {
    font-size: 0.7rem;
    line-height: 1.4;
    font-weight: 400;
    margin: 10px 0 5px;
  }
}
