.project-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-content {
  padding: 80px 0 40px;
}

.mockups .container-sm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0 40px;
}

.images-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.image-hero {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-hero img {
  display: block;
  height: auto;
  display: block;
  margin: 0 auto;
}

.images-hero img {
  width: 100%;
  max-width: 150px;
  max-height: 100%;
}

.project-text {
  margin-top: 30px;
}

.project-tags {
  color: #73808d;
  margin-top: 15px;
  font-size: 0.9rem;
}

@media (max-width: 1000px) {
  .project-heading {
    padding: 40px 0px 0px;
  }
}

@media (max-width: 768px) {
  .project-heading {
    padding: 0 0;
  }

  .project-content {
    padding: 70px 20px 20px;
  }

  .project-text {
    margin-top: 0px;
  }

  .project-tags {
    color: #73808d;
    margin-top: 15px;
    font-size: 0.7rem;
  }

  .images-hero img {
    width: 100%;
    max-width: 110px;
    max-height: 100%;
  }
}
