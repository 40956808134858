.role {
  padding: 40px 0 40px;
}

.project-tags {
  color: #73808d;
  margin-top: 15px;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .role {
    padding: 40px 20px 40px;
  }

  .project-tags {
    font-size: 0.7rem;
  }
}
