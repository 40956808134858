.work {
  padding: 40px 0 40px;
}

.work-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  gap: 30px;
}

.work-image img {
  -webkit-filter: sepia(0%);
  filter: sepia(0%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.work-image:hover img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}

@media (max-width: 768px) {
  .work-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 7.5px 15px;
    gap: 15px;
  }

  .work-image img {
    border-radius: 50px;
  }
}
