.back-next {
  position: absolute;
  width: 100%;
  padding: 110px 70px 0px 70px;
  transition: 0.5s;
  z-index: 500;
}

.back-next .container-lg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-next ul li a {
  padding: 0px 20px;
  display: block;
  font-weight: 600;
  transition: 0.5s;
}

.back-next ul li a:hover {
  color: #b1c3d7;
}

@media (max-width: 768px) {
  .back-next {
    position: absolute;
    width: 100%;
    padding: 70px 20px 0px 20px;
    transition: 0.5s;
    z-index: 500;
  }

  .back-next ul li a {
    font-weight: 400;
  }
}
