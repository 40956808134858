.graphic-design {
  padding: 40px 0 40px;
}

.images-gd {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  width: 100%;
  gap: 10px;
}

.images-gd img {
  width: 100%;
  max-width: 220px;
  max-height: 100%;
}

@media (max-width: 768px) {
  .graphic-design {
    padding: 40px 0px 40px;
  }

  .images-gd img {
    width: 100%;
    max-width: 160px;
    max-height: 100%;
  }
}
