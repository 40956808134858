.about {
  padding: 40px 0 0px;
}

.about-images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.about-text1 {
  margin-bottom: 20px;
}

.about-text2 {
  margin-bottom: 50px;
}

.contact {
  border-bottom: 1.5px solid;
  transition: 0.5s;
}

.contact:hover {
  color: #b1c3d7;
}

.contact-heading {
  padding-top: 40px;
}

@media (max-width: 768px) {
  .about {
    padding: 40px 20px 0px;
  }
  .about-images {
    flex-direction: column;
    gap: 10px;
  }
}
