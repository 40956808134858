/* Basic reset */

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.scrollToTop {
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  display: block; /* Temporarily make the button always visible */
}

/* Hover effect */
.scrollToTop:hover {
  background-color: #b1c3d7;
  transform: translateY(-5px);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}
