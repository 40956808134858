/* Learnings */
.learnings {
  padding: 20px 0px 80px 0px;
}

@media (max-width: 768px) {
  .learnings {
    padding: 20px 20px 60px;
  }

  .learnings-header {
    font-size: 1.8rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 30px 0 20px;
  }

  .learnings-text {
    font-size: 0.7rem;
    line-height: 1.4;
    font-weight: normal;
    margin: 10px 0 5px;
  }
}
