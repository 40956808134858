.the-project {
  padding: 40px 0 10px;
}

.row-heading {
  font-weight: 500;
}
.p1-row1,
.p1-row2 {
  padding: 30px 20px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.p1-row1 .left {
  flex: 0 0 40%;
}

.p1-row1 .right {
  flex: 0 0 60%;
}

.p1-row2 .right {
  flex: 0 0 50%;
}

.p1-row2 .left {
  flex: 0 0 50%;
}

.row-text {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .the-project {
    padding: 40px 20px 40px;
  }

  .row-text {
    font-size: 15px;
    font-weight: 250;
  }
  .p1-row1 {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .p1-row2 {
    padding: 30px 0px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
}
